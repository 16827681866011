import React from 'react';
import Picture from '../picture/Picture';
import Slider from '../slider/Slider';

const AboutMe = () => {
  
  return (
    <>
      <Picture />
      <Slider />
    </>
  );
};
export default AboutMe;
